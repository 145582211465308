import React from "react"
import { Link } from "gatsby"

import SEO from "../../components/seo"
import AirwayCase from "./AirwayCase"
import ResponsiveAd from "../../components/ResponsiveAd"

import styled from "styled-components"

export default function Airway(props) {
  return (
    <AirwayContainer>
      <AdContainer>
        <div className="ad-inner">
          <ResponsiveAd />
        </div>
      </AdContainer>
      <SEO
        title={"Airway and Rapid Sequence Intubation - Nucleotype"}
        description={
          "Rapid sequence intubation is an airway management technique that creates the optimal conditions the clinician needs for intubation."
        }
        url={"https://www.nucleotype.com/airway"}
      />
      <h1>Airway</h1>
      <hr />
      <SectionGridHalf>
        <div>
          <h3>INTRO TO RSI</h3>
          <ArticleContainer to="airway/rsi-indications">
            <h2>
              Indications and Contraindications to Rapid Sequence Intubation and
              Induction
            </h2>
            <p>
              Indications for RSI are simply those patients who cannot
              ventilate, oxygenate or fail to protect their aiway.
              Contraindications are few and relative.
            </p>
          </ArticleContainer>
          <ArticleContainer to="airway/rsi-process">
            <h2>Seven P's of RSI - The Process of Rapid Sequence Intubation</h2>
            <p>
              RSI involves the use of an induction agent, followed immediately
              by a neuromuscular blocking agent to quickly create an optimal
              environment for intubation.
            </p>
          </ArticleContainer>
        </div>
        <div className="side advertisement"></div>
      </SectionGridHalf>
      <hr />
      <h3>PHARMACOLOGY</h3>
      <SectionGridHalf>
        <div>
          <Medication>
            <div className="med-label induction">INDUCTION AGENT</div>
            <h2>Etomidate</h2>
            <div className="med-data-container">
              <div className="med-data dose">Dose: 0.3 mg/kg</div>
              <div className="med-data">Onset: 15 - 45 sec</div>
              <div className="med-data">Duration: 3 - 12 min</div>
            </div>
          </Medication>
          <Medication>
            <div className="med-label induction">INDUCTION AGENT</div>
            <h2>Midazolam</h2>
            <div className="med-data-container">
              <div className="med-data dose">Dose: 0.1 - 0.3 mg/kg</div>
              <div className="med-data">Onset: 30 - 60 sec</div>
              <div className="med-data">Duration: 15 - 30 min</div>
            </div>
          </Medication>
          <Medication>
            <div className="med-label induction">INDUCTION AGENT</div>
            <h2>Ketamine</h2>
            <div className="med-data-container">
              <div className="med-data dose">Dose: 1 - 2 mg/kg</div>
              <div className="med-data">Onset: 45 - 60 sec</div>
              <div className="med-data">Duration: 10 - 20 min</div>
            </div>
          </Medication>
          <Medication>
            <div className="med-label induction">INDUCTION AGENT</div>
            <h2>Propofol</h2>
            <div className="med-data-container">
              <div className="med-data dose">Dose: 1.5 - 3 mg/kg</div>
              <div className="med-data">Onset: 15 - 45 sec</div>
              <div className="med-data">Duration: 5 - 10 min</div>
            </div>
          </Medication>
          <Medication>
            <div className="med-label induction">INDUCTION AGENT</div>
            <h2>Thiopental</h2>
            <div className="med-data-container">
              <div className="med-data dose">Dose: 3 - 5 mg/kg</div>
              <div className="med-data">Onset: &lt; 30 sec</div>
              <div className="med-data">Duration: 5 - 10 min</div>
            </div>
          </Medication>
        </div>
        <div>
          <Medication>
            <div className="med-label paralytic">PARALYTIC</div>
            <h2>Rocuronium</h2>
            <div className="med-data-container">
              <div className="med-data dose">Dose: 1 - 1.2 mg/kg</div>
              <div className="med-data">Onset: 45 - 60 sec</div>
              <div className="med-data">Duration: 45 min</div>
            </div>
          </Medication>
          <Medication>
            <div className="med-label paralytic">PARALYTIC</div>
            <h2>Succinylcholine</h2>
            <div className="med-data-container">
              <div className="med-data dose">Dose: 1.5 mg/kg</div>
              <div className="med-data">Onset: 45 - 60 sec</div>
              <div className="med-data">Duration: 6 - 10 min</div>
            </div>
          </Medication>
        </div>
      </SectionGridHalf>
      <AdContainer>
        <div className="ad-inner">
          <ResponsiveAd />
        </div>
      </AdContainer>
      <hr />
      <SectionGridHalf>
        <div>
          <h3>CHOOSING YOUR MEDICATIONS</h3>
          <ArticleContainer to="airway/choosing-induction-agent">
            <h2>Choosing an Induction Agent</h2>
            <p>
              Induction agents used in rapid sequence intubation all lead to a
              similar endpoint which includes providing sedation and amnesia and
              can improve intubating conditions.
            </p>
          </ArticleContainer>
          <ArticleContainer to="airway/rocuronium-vs-succinylcholine">
            <h2>
              Rocuronium Versus Succinylcholine for Rapid Sequence Intubation
            </h2>
            <p>
              There are two neuromuscular blocking agents that have short enough
              onset times to be used in rapid sequence intubation and induction:
              succinylcholine and rocuronium.
            </p>
          </ArticleContainer>
        </div>
        <div className="side">
          <hr className="mobile-line" />
          <h3>CLINICAL CASES</h3>
          <AirwayCase
            title={"Succinylcholine in RSI"}
            desc={
              "In which of the following scenarios is succinylcholine a safe alternative to rocuronium during intubation?"
            }
            caseId={"l9avjgaG"}
            caseType="QA"
          />
          <AirwayCase
            title={"Shortness of breath after house fire"}
            desc={
              "A 58-year-old male presents with shortness of breath and hoarseness following a house fire."
            }
            caseId={"Vyb8Krav"}
            caseType="Clinical Scenario"
          />
        </div>
      </SectionGridHalf>
    </AirwayContainer>
  )
}

const AirwayContainer = styled.div`
  padding: 0 15px 15px 15px;
  max-width: 1000px;
  margin: auto;
  h1 {
    font-size: 50px;
    margin-bottom: 40px;
  }
  h3 {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 35px;
    font-weight: 500;
  }
  .mobile-line {
    display: none;
  }
  @media (max-width: 1024px) {
    max-width: 100%;
  }
  @media (max-width: 768px) {
    .mobile-line {
      display: block;
    }
  }
`

const ArticleContainer = styled(Link)`
  text-decoration: none;
  box-shadow: none;
  display: block;
  color: black;
  max-width: 1000px;
  margin: auto;
  margin-bottom: 45px;
  h2 {
    font-size: 21px;
    margin-top: 0;
    margin-bottom: 8px;
    line-height: 150%;
  }
  p {
    font-size: 18px;
    line-height: 130%;
    margin-top: 0;
    font-family: adobe-garamond-pro, serif;
  }
`

const AdContainer = styled.div`
  margin-bottom: 40px;
  position: relative;
  padding: 50px;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background-color: #f7f7f7;
  .ad-inner {
    text-align: center;
    max-width: 900px;
    margin: auto;
  }
`

const SectionGridTwoOne = styled.section`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 25px;
  margin-top: 40px;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .side {
      display: none;
    }
  }
`

const SectionGridHalf = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 65px;
  margin-top: 40px;
  margin-bottom: 50px;
  .advertisement {
    background-color: #f7f7f7;
    padding: 10px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
`

const Medication = styled.div`
  margin-bottom: 50px;
  .med-label {
    font-family: futura-pt, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    &.induction {
      color: #376dbf;
    }
    &.paralytic {
      color: #e37f23;
    }
  }
  h2 {
    font-size: 28px;
    margin-top: 0;
    margin-bottom: 8px;
  }
  .med-data-container {
    display: grid;
    grid-template-columns: max-content max-content max-content;
    grid-gap: 10px;
    margin-bottom: 5px;
    .med-data {
      font-family: futura-pt, sans-serif;
      font-size: 15px;
      font-weight: 300;
      line-height: 24px;
      background: #eaeaea;
      padding: 3px 10px;
      border-radius: 5px;
      text-align: center;
      &.dose {
        background: #6a6a6a;
        color: white;
        font-weight: 400;
      }
      &.dose-light {
        background: #a5a5a5;
        color: white;
        font-weight: 400;
      }
    }
  }
  @media (max-width: 1024px) {
    .med-data-container {
      .med-data {
        background: none;
        font-weight: 400;
        font-size: 12px;
        padding: 0px 5px;
        &.dose,
        &.dose-light {
          background: none;
          color: black;
          padding: 0;
        }
        &.dose {
          font-size: 14px;
        }
      }
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`
