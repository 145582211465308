import React, { useState } from "react"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

// Styles
import styled from "styled-components"

export default function ClinicalCase(props) {
  const { title, desc, caseId, caseType } = props

  function handleClick() {
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: "Case",
      // string - required - Type of interaction (e.g. 'play')
      action: "Click",
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: caseId,
    })
    console.log("clicked case")
    window.open("https://rounds.medzcool.com/case/" + caseId, "_blank")
  }

  return (
    <ClinicalCaseContainer onClick={() => handleClick()}>
      {console.log("airway case present")}
      <div>
        <TopLabel>
          {caseType == "QA" ? "QUESTION & ANSWER" : "CLINICAL SCENARIO"}
        </TopLabel>
        <h2>{title}</h2>

        <p className="desc">{desc}</p>
        <p className="answer">Answer</p>
      </div>
    </ClinicalCaseContainer>
  )
}

const TopLabel = styled.div`
  font-family: futura-pt, sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 100%;
  color: #bf1736;
`

const ClinicalCaseContainer = styled.div`
  cursor: pointer;
  margin-bottom: 50px;
  h2 {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 8px;
  }
  p {
    font-size: 18px;
    line-height: 130%;
    font-family: adobe-garamond-pro, serif;
    color: black;
    margin-top: 0;
  }
  .desc {
    margin-bottom: 2px;
  }
  .answer {
    color: blue;
    text-decoration: underline;
  }
`
